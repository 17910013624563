<script>
	import Base from '@backend/Base.vue';
	import BOGen from '@helper/BOGen';
	import Gen from '@helper/Gen';
	import swal from 'sweetalert';

	export default {
		name: "BoPagination",
		extends: Base,
		data() {
			return {
				Name: "BoPagination",
				mrLevel: {},
				sosial_media: {},
				sosial_media_detail: {},
				sc_footer: {},
				cmp_name: {},
				cmp_address: {},
				cmp_number: {},
				cmp_email: {},
				marketing_wa: {},
				pagination: {},
				pagination_favorite:{},
				greeting_wa: {},
				username_instagram: {},
				open_service: {},
				banner_img:{},
				banner_link:{},
				banner_text_en:{},
				banner_text_id:{},
				banner_status:{},
			}
		},
		mounted() {
			this.$set(this.$root, 'page', this)
			this.refreshData()
		},
		methods: {
			updatePagination(e, callback) {
				if (e && e.btnLoading()) return;
				let input = {}
				input.pagination = this.pagination
				input.type = 'updatePagination'
				BOGen.apirest("/" + this.Name, input, (err, resp) => {
					if (e) e.btnUnloading()
					if (resp.error == 1) return Gen.info(resp.message, resp.style, 3000, '.info-pagination')
					if (resp.success) {
						Gen.info(resp.message, resp.style, 3000, '.info-pagination')
						this.refreshData()
					}
				}, "POST")
			},
			updatePaginationFavorite(e, callback) {
				if (e && e.btnLoading()) return;
				let input = {}
				input.pagination = this.pagination_favorite
				input.type = 'updatePaginationFavorite'
				BOGen.apirest("/" + this.Name, input, (err, resp) => {
					if (e) e.btnUnloading()
					if (resp.error == 1) return Gen.info(resp.message, resp.style, 3000, '.info-pagination-favorite')
					if (resp.success) {
						Gen.info(resp.message, resp.style, 3000, '.info-pagination-favorite')
						this.refreshData()
					}
				}, "POST")
			},
		},
		watch: {
			'$route.query'() {
				this.refreshData()
			},
			'filter.level'() {
				this.search()
			},
		}
	}
</script>

<template>
	<div class="container-fluid">
		<PageTitle></PageTitle>

		<!-- ======================================= LIST DATA ======================================= -->
		<div class="row">

			<div class="col-12">
				<div class="card">
					<div class="card-body">
						<div class="d-flex justify-content-between align-items-center">
							<h5 class="card-title">Pagination</h5>
						</div>
						<hr>
						<VForm @resp="updatePagination">
							<div class="row">
								<div class="col-md-12 info-pagination"></div>
								<div class="col-2">
									<!-- <BoField name="aws_val" :label="'Pagination'" v-model="pagination.aws_val" :attr="{type:'Number',min:3}"></BoField> -->
									<label class="control-label">Pagination</label>
									<input type="number" v-model="pagination.aws_val" min="4" class="form-control">
								</div>
								<div class="col-10 text-right mt-4 ">
									<button type="submit" class="btn btn-info btn-rounded btn-loading">Submit Changes</button>
								</div>
							</div>
						</VForm>
					</div>
				</div>
			</div>
		</div>
		<div id="editSosmed" class="modal" tabindex="-1" role="dialog" aria-labelledby="add_team" aria-hidden="true">
			<div class="modal-dialog">
				<div class="modal-content">
					<div class="modal-header">
						<h4 class="modal-title" id="prod_cat">Edit Social Media</h4>
						<button type="button" class="close" data-dismiss="modal" aria-hidden="true">×</button>
					</div>
					<VForm @resp="editSosialMedai" action="post">
						<div class="modal-body">
							<div class="row">
								<div class="col-12">
									<div class="form-group">
										<BoField v-model="sosial_media_detail.mas_val"
											:label="sosial_media_detail.mas_title+' Link'"></BoField>
									</div>
								</div>
								<div class="col-12">
									<Checkbox class="mt-4" v-model="sosial_media_detail.mas_status">Active</Checkbox>
								</div>
							</div>
						</div>
						<div class="modal-footer">
							<button type="submit" class="btn btn-rounded btn-loading btn-info">Submit</button>
						</div>
					</VForm>
				</div>
			</div>
		</div>
	</div>
</template>